import { useLoaderData } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { BusinessAccount } from "../../../services/businessAccount/businessAccountModel";
import ProAccountForm from "./ProAccountForm";
import ProAddressForm from "./ProAddressForm";
import { Country } from "../../../services/country/countryModel";
import ProPaymentMethodForm from "./ProPaymentMethodForm";
import ProSubscriptionForm from "./ProSubscriptionForm";
import { formatDate } from "../../../react-helpers/date";
import DisplayPrice from "../../../components/DisplayPrice";
import Icon from "../../../components/Icon";

const BusinessAccountManagementPage = () => {
  const { businessAccount, countries } = useLoaderData() as {
    businessAccount: BusinessAccount;
    countries: Country[];
  };

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton fallbackUrl="/app/dashboard" />
          <h1 className="page_title">Votre compte pro</h1>
        </div>
      </div>
      <div className="page-content grid--2-1 lblocks">
        <div className="lblocks">
          <div className="cblocks">
            <h2 className="section_title">Informations du compte</h2>
            <div className="card">
              <div className="card_body">
                <ProAccountForm businessAccount={businessAccount} />
              </div>
            </div>
          </div>

          <div className="cblocks">
            <h2 className="section_title">Adresse de facturation</h2>
            <div className="card">
              <div className="card_body">
                <ProAddressForm
                  businessAccount={businessAccount}
                  countries={countries}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lblocks">
          <div className="cblocks">
            <h2 className="section_title">Moyen de paiement</h2>
            <div className="card">
              <div className="card_body">
                <ProPaymentMethodForm businessAccount={businessAccount} />
              </div>
            </div>
          </div>

          <div className="cblocks">
            <h2 className="section_title">Abonnement</h2>
            <div className="card">
              <div className="card_body">
                <ProSubscriptionForm
                  businessAccount={businessAccount}
                  plan={businessAccount.plans?.[0]}
                  subscription={businessAccount.plans?.[0].Subscription}
                />
              </div>
            </div>
          </div>

          <div className="cblocks">
            <h2 className="section_title">Factures</h2>

            <div className="cblocks --xs">
              {businessAccount.stripeInvoices?.map((invoice) => (
                <div key={invoice.link} className="file">
                  <a
                    className="file_name"
                    href={invoice.link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <span>
                      {formatDate(invoice.date)}
                      {" - "}
                      <DisplayPrice
                        amount={invoice.paidAmount}
                        currency="EUR"
                        taxFree={false}
                      />
                      {invoice.label ? ` - ${invoice.label}` : null}
                    </span>
                  </a>
                  <a href={invoice.link} download className="download-file">
                    <Icon name="download" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessAccountManagementPage;
