import { BookingProfile } from "../bookingProfile/bookingProfileModel";
import { toastsWithIntl } from "../toastService";
import {
  createBookmarkRequest,
  deleteBookmarkRequest,
  getBookmarksRequest,
} from "./bookmarkApi";
import { Bookmark } from "./bookmarkModel";

interface BookmarkService {
  getAllBookmarks(): Promise<Bookmark>;
  createBookmark(bookingProfileId: BookingProfile["id"]): Promise<Bookmark>;
  deleteBookmark(bookmarkId: Bookmark["id"]): Promise<void>;
}

export function bookmarkService(): BookmarkService {
  const { toastError } = toastsWithIntl(["global"]);

  const getAllBookmarks: BookmarkService["getAllBookmarks"] = () => {
    return getBookmarksRequest();
  };

  const createBookmark: BookmarkService["createBookmark"] = (
    bookingProfileId,
  ) => {
    return createBookmarkRequest(bookingProfileId).catch(() => {
      toastError("global:GENERIC_ERROR");
    });
  };

  const deleteBookmark: BookmarkService["deleteBookmark"] = (bookmarkId) => {
    return deleteBookmarkRequest(bookmarkId).catch(() => {
      toastError("global:GENERIC_ERROR");
    });
  };

  return {
    getAllBookmarks,
    createBookmark,
    deleteBookmark,
  };
}
