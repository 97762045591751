import { useState } from "react";
import Dialog from "../../../components/Dialog";
import CampaignUserDialogContent from "./CampaignUserDialogContent";
import { User } from "../../../services/user/userModel";
import CampaignBookingDialogContent from "./CampaignBookingDialogContent";
import CampaignConfirmationDialogContent from "./CampaignConfirmationDialogContent";
import { Campaign } from "../../../services/campaign/campaignModel";
import { campaignService } from "../../../services/campaign/campaignService";
import {
  Booking,
  BookingRequest,
} from "../../../services/bookingRequest/bookingRequestModel";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import { cx } from "../../../react-helpers/css";
import useReload from "../../../hooks/useReload";

enum Step {
  UserInfo,
  Booking,
  Confirmation,
}

const { getPublicBookingRequestByUuid } = bookingRequestService();
const { createBookingRequest } = campaignService();

const CampaignDialog = ({
  onClose,
  campaign,
  currentUser,
  initialBookingRequest,
}: {
  onClose(): void;
  campaign: Campaign;
  currentUser?: User;
  initialBookingRequest?: BookingRequest;
}) => {
  const reload = useReload();

  const [step, setStep] = useState(
    initialBookingRequest ? Step.Booking : Step.UserInfo,
  );
  const [bookingRequest, setBookingRequest] = useState<BookingRequest | null>(
    initialBookingRequest ?? null,
  );
  const [booking, setBooking] = useState<Booking | null>(null);

  return (
    <Dialog
      onClose={onClose}
      className={cx([step === Step.Booking && bookingRequest && "--full"])}
    >
      <>
        {step === Step.UserInfo && (
          <CampaignUserDialogContent
            onClose={onClose}
            currentUser={currentUser}
            nextStep={(externalUser) => {
              return createBookingRequest(campaign.uuid, externalUser).then(
                (createdBookingRequest) => {
                  setBookingRequest(createdBookingRequest);

                  if (createdBookingRequest.status === "PAID")
                    setStep(Step.Booking);
                  else {
                    setBooking(createdBookingRequest.booking!);
                    setStep(Step.Confirmation);
                  }
                },
              );
            }}
          />
        )}
        {step === Step.Booking && bookingRequest && (
          <CampaignBookingDialogContent
            bookingRequest={bookingRequest}
            nextStep={() => {
              void getPublicBookingRequestByUuid(bookingRequest.uuid).then(
                (bookingRequestWithBooking) => {
                  reload();
                  setBooking(bookingRequestWithBooking.booking!);
                  setStep(Step.Confirmation);
                },
              );
            }}
          />
        )}
        {step === Step.Confirmation && booking && bookingRequest && (
          <CampaignConfirmationDialogContent
            isLoggedIn={!!currentUser}
            bookingRequest={bookingRequest}
            booking={booking}
          />
        )}
      </>
    </Dialog>
  );
};

export default CampaignDialog;
