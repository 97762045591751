import { boolean, date, number, object, string } from "yup";
import SxForm from "../../../../forms/SxForm";
import SxField from "../../../../forms/fields/SxField";
import { User } from "../../../../services/user/userModel";
import SubmitButton from "../../../../components/forms/SubmitButton";
import { Campaign } from "../../../../services/campaign/campaignModel";
import ValidationErrors from "../../../../forms/ValidationErrors";
import {
  MAXIMUM_VISIO_PRICE,
  MINIMUM_VISIO_PRICE,
} from "../../../../services/config";
import {
  transformInvalidDateToNull,
  transformNumericStringToNumberOrNull,
} from "../../../../react-helpers/yup";
import { useMemo } from "react";
import { isUserAdminOfCampaign } from "../../../../services/campaign/campaignService";
import { BusinessAccount } from "../../../../services/businessAccount/businessAccountModel";
import DisplayPrice from "../../../../components/DisplayPrice";
import { Association } from "../../../../services/data/dataModel";
import SxCheckbox from "../../../../forms/fields/SxCheckbox";

interface Props {
  initialValues?: Campaign;
  onSubmit(values: Campaign): Promise<void>;
  currentUser: User;
  businessAccount?: BusinessAccount;
  amountVisioBooked?: number;
  associations: Association[];
}

const CampaignForm = ({
  initialValues,
  onSubmit,
  currentUser,
  amountVisioBooked,
  businessAccount,
  associations,
}: Props) => {
  const currentUserIsAdmin = useMemo(() => {
    return (
      !initialValues ||
      (isUserAdminOfCampaign(currentUser, initialValues) ?? false)
    );
  }, [initialValues, currentUser]);

  const [isDraft, isOngoing, isClosed] = useMemo(() => {
    return [
      !initialValues || initialValues.status === "DRAFT",
      initialValues?.status &&
        ["ACTIVE", "SUSPENDED"].includes(initialValues.status),
      initialValues?.status === "CLOSED",
    ];
  }, [initialValues]);

  return (
    <SxForm
      initialValues={{
        name: "",
        endDate: undefined,
        maxVisio: undefined,
        type: undefined,
        context: undefined,
        price: undefined,
        associationId: null,
        probonoCampaign: false,
        ...(initialValues
          ? {
              ...initialValues,
              price: initialValues.price! / 100,
            }
          : {}),
        virtualType: initialValues?.type === "OPEN" ? "OPEN" : "TARGETED",
        virtualMode:
          initialValues?.type === "OPEN"
            ? undefined
            : initialValues?.type ?? "MANAGED",
        referent: `${currentUser.firstname} ${currentUser.lastname} - ${currentUser.email}`,
      }}
      enableReinitialize
      onSubmit={(values) => {
        const type =
          values.virtualType === "OPEN" ? "OPEN" : values.virtualMode!;
        return onSubmit({
          ...values,
          price: values.price! * 100,
          status:
            initialValues?.status ?? (type === "OPEN" ? "DRAFT" : "ACTIVE"),
          referent: undefined,
          referentId: currentUser.id,
          businessAccountId:
            businessAccount?.id ?? initialValues!.businessAccountId,
          type,
        });
      }}
      validationSchema={object({
        name: string()
          .label("Nom de la campagne")
          .required()
          .disabled(!currentUserIsAdmin),
        endDate: date()
          .label("Date de fin de campagne (optionnelle)")
          .transform(transformInvalidDateToNull)
          .test({
            message: "Renseignez une date dans le futur",
            test: (value, context) => {
              if (context.schema.spec?.meta?.disabled) return true;
              return !value || value > new Date();
            },
          })
          .nullable()
          .disabled(!currentUserIsAdmin),
        maxVisio: number()
          .label("Nombre max. de visios (optionnel)")
          .transform(transformNumericStringToNumberOrNull)
          .min(
            (amountVisioBooked ?? 0) > 0 ? amountVisioBooked! : 1,
            `Le nombre de visios doit être au minimum ${(amountVisioBooked ?? 0) > 0 ? amountVisioBooked! : 1}`,
          )
          .max(1000, "Le nombre de visios doit être au maximum de 1000")
          .nullable()
          .disabled(!currentUserIsAdmin),
        price: number()
          .label(
            "Montant que vous proposez à votre interlocuteur pour une visio de 30 minutes (€ HT)",
          )
          .transform(transformNumericStringToNumberOrNull)
          .min(
            MINIMUM_VISIO_PRICE,
            `Le montant proposé doit être au minimum de ${MINIMUM_VISIO_PRICE}€ HT`,
          )
          .max(
            MAXIMUM_VISIO_PRICE,
            `Le montant proposé doit être au maximum de ${MAXIMUM_VISIO_PRICE}€ HT`,
          )
          .disabled(!!isOngoing || !currentUserIsAdmin)
          .required(),
        referent: string().label("Référent de la campagne").disabled(),
        context: string()
          .label(
            "Rédigez votre demande. Soyez précis, c’est ce que vos interlocuteurs liront !",
          )
          .multiline()
          .max(2000, "La demande soit faire au maximum 2000 caractères")
          .required(),
        virtualType: string()
          .label("Type de campagne")
          .required()
          .disabled(!isDraft),
        virtualMode: string()
          .label("Mode de diffusion")
          .when("virtualType", {
            is: "TARGETED",
            then: (schema) => schema.required(),
          })
          .disabled(!isDraft),
        probonoCampaign: boolean().label("Campagne 100% dons"),
        associationId: number()
          .label("Association")
          .transform(transformNumericStringToNumberOrNull)
          .nullable()
          .when("probonoCampaign", {
            is: false,
            then: (schema) => schema.transform(() => null).notVisible(),
          }),
      })}
      disabled={isClosed}
    >
      {({ values }) => (
        <div className="lblocks">
          <div className="grid--2">
            <SxField
              name="name"
              placeholder="ex: AAAA-MM - Campagne DSI - Patrick"
            />
            <div>
              <SxField name="endDate" />
              <div className="field-tip">
                Au delà de cette date, vos contacts ne pourront plus prendre de
                RDV
              </div>
            </div>
          </div>

          <div className="card --bg">
            <div className="card_body lblocks">
              <div className="grid--2">
                <div>
                  <SxField name="price" placeholder="ex: 100" />
                  <div className="field-tip">
                    Montant minimum : {MINIMUM_VISIO_PRICE}€
                  </div>
                  <div className="field-tip">
                    Montant maximum : {MAXIMUM_VISIO_PRICE}€
                  </div>
                </div>
                <div>
                  <SxField name="maxVisio" placeholder="ex: 10" />
                  <div className="field-tip">
                    Nombre maximum de visios que vous souhaitez réaliser dans
                    cette campagne.
                  </div>
                </div>
              </div>

              {!!values.price &&
                values.price >= MINIMUM_VISIO_PRICE &&
                values.price <= MAXIMUM_VISIO_PRICE && (
                  <div className="info">
                    <div>
                      {" "}
                      À chaque RDV accepté par l’un de vos interlocuteurs, nous
                      prélèverons{" "}
                      <strong>
                        <DisplayPrice
                          amount={values.price * 100}
                          currency="EUR"
                          taxFree
                        />
                      </strong>{" "}
                      (
                      <DisplayPrice
                        amount={values.price * 1.2 * 100}
                        currency="EUR"
                        taxFree={false}
                      />
                      )
                    </div>
                    {!!values.maxVisio && (
                      <>
                        <div>
                          Cette campagne vous coûtera donc au maximum{" "}
                          <strong>
                            <DisplayPrice
                              amount={values.maxVisio * values.price * 100}
                              currency="EUR"
                              taxFree
                            />
                          </strong>{" "}
                          (
                          <DisplayPrice
                            amount={values.maxVisio * values.price * 1.2 * 100}
                            currency="EUR"
                            taxFree={false}
                          />
                          ).
                        </div>
                        <div className="body--20">
                          Une fois ce montant atteint, les destinataires ne
                          pourront plus accepter la sollicitation.
                        </div>
                      </>
                    )}
                  </div>
                )}

              <SxCheckbox
                name="probonoCampaign"
                isDisabled={!!isOngoing || !currentUserIsAdmin}
              />

              <SxField
                as="select"
                name="associationId"
                disabled={!!isOngoing || !currentUserIsAdmin}
              >
                <option>
                  Je laisse le choix de l'association à mes interlocuteurs
                </option>
                {associations.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.labelFr}
                  </option>
                ))}
              </SxField>
            </div>
          </div>

          <div className="grid--2">
            <div className="cblocks">
              <SxField name="virtualType" as="select">
                <option value="OPEN">Campagne publique</option>
                <option value="TARGETED">Campagne ciblée</option>
              </SxField>
              {!initialValues && values.virtualType === "OPEN" && (
                <div className="info">
                  <div>
                    Une campagne publique génère un lien vers une page
                    d'inscription que vous pouvez partager librement (par email,
                    sur LinkedIn, etc.) pour permettre aux personnes de réserver
                    une visio.
                  </div>
                  <div className="--bold">
                    Attention : ce lien sera public ; toute personne en sa
                    possession pourra prendre RDV. Tisio ne partagera pas ce
                    lien ; vous seul en contrôlez la diffusion.
                  </div>
                </div>
              )}
              {!initialValues && values.virtualType === "TARGETED" && (
                <div className="info">
                  Une campagne ciblée génère des liens de réservation
                  individuels. Seuls les contacts importés à l’étape suivante
                  pourront prendre RDV.
                </div>
              )}
            </div>
            {values.virtualType === "TARGETED" && (
              <div className="cblocks">
                <SxField name="virtualMode" as="select">
                  <option value="MANAGED">Envoi par Tisio</option>
                  <option value="TARGETED">Envoi par vos soins</option>
                </SxField>
                {!initialValues && values.virtualMode === "MANAGED" && (
                  <div className="info">
                    Importez vos contacts à l’étape suivante, Tisio les
                    sollicitera pour vous.
                  </div>
                )}
                {!initialValues && values.virtualMode === "TARGETED" && (
                  <div className="info">
                    Importez vos contacts à l’étape suivante, un lien individuel
                    sera généré pour chacun d’entre-eux, vous permettant
                    d'envoyer personnellement les sollicitations.
                  </div>
                )}
              </div>
            )}
            <SxField name="referent" />
          </div>

          <div>
            <SxField name="context" />
            <div className="field-tip">
              <a
                className="link--accent --s"
                href="https://info.tisio.fr/blog/tuto-redaction-sollicitation-tisio/"
                target="_blank"
                rel="noreferrer"
              >
                Comment rédiger une demande de sollicitation efficace ?
              </a>
            </div>
          </div>

          <div className="form_footer lblocks">
            <ValidationErrors />

            <div className="lblocks">
              <SubmitButton className="btn" type="submit">
                {initialValues ? "Enregistrer" : "Créer la campagne"}
              </SubmitButton>
            </div>
          </div>
        </div>
      )}
    </SxForm>
  );
};

export default CampaignForm;
