import { baseAPI } from "../api";
import { BookingProfile } from "../bookingProfile/bookingProfileModel";
import { Bookmark } from "./bookmarkModel";

export function getBookmarksRequest() {
  return baseAPI.get("/bookmarks").then(({ data }) => data);
}

export function createBookmarkRequest(bookingProfileId: BookingProfile["id"]) {
  return baseAPI
    .post(`/bookmarks/booking-profiles/${bookingProfileId}`)
    .then(({ data }) => data);
}

export function deleteBookmarkRequest(bookmarkId: Bookmark["id"]) {
  return baseAPI.delete(`/bookmarks/${bookmarkId}`).then(({ data }) => data);
}
