import { authService } from "../services/auth/authService";
import { RouteObjectWithCondition } from "./routerConditions.tsx";
import AppLayout from "../components/layouts/AppLayout";
import UserDashboardPage from "../views/dashboard/UserDashboardPage";
import { redirect } from "react-router-dom";
import AdminPage from "../views/admin/AdminDashboardPage";
import ProfilesListPage from "../views/profilesList/ProfilesListPage";
import { getBookingProfilesRequest } from "../services/bookingProfile/bookingProfileApi";
import { getAllConfigDataRequest } from "../services/data/dataApi";
import AccountPage from "../views/user/UserAccount/UserAccountPage";
import { userService } from "../services/user/userService";
import UserIdentityForm from "../views/user/UserAccount/UserIdentityForm";
import UserPasswordForm from "../views/user/UserAccount/UserPasswordForm";
import { getAllCountriesRequest } from "../services/country/countryApi";
import BookingProfileManagementPage from "../views/user/BookingProfile/bookingProfileManagementPage/BookingProfileManagementPage";
import BookingProfilePositionForm from "../views/user/BookingProfile/BookingProfilePositionForm";
import BookingProfileDescriptionForm from "../views/user/BookingProfile/BookingProfileDescriptionForm";
import BookingProfilePreferencesForm from "../views/user/BookingProfile/BookingProfilePreferencesForm";
import { promiseAllObject } from "../react-helpers/promise";
import {
  dataService,
  mapConfigDataToAutocompleteOptions,
} from "../services/data/dataService";
import { bookingProfileService } from "../services/bookingProfile/bookingProfileService";
import UserCalendarPage from "../views/user/UserCalendarPage";
import { bookingRequestService } from "../services/bookingRequest/bookingRequestService";
import BookingRequestNotFoundPage from "../views/solicitation/bookingRequest/BookingRequestNotFoundPage";
import { paymentService } from "../services/payment/paymentService";
import RequestsAndBookingsPage from "../views/solicitation/requestsAndBookings/RequestsAndBookingsPage";
import { getCurrentUserBookingRequestsRequest } from "../services/bookingRequest/bookingRequestApi";
import RequestsSections from "../views/solicitation/requestsAndBookings/RequestsSection";
import AppointmentsSection from "../views/solicitation/requestsAndBookings/AppointmentsSection";
import BookingClosurePage from "../views/solicitation/closure/BookingClosurePage";
import BookingProfilePage from "../views/solicitation/bookingProfile/BookingProfilePage";
import BookingRequestPage from "../views/solicitation/bookingRequest/bookingRequestPage/BookingRequestPage";
import {
  getDataForUserDashboardRequest,
  getUserCreditsRequest,
} from "../services/user/userApi";
import BookingRequestPaymentPage from "../views/solicitation/bookingRequest/payment/BookingRequestPaymentPage";
import EWalletPage from "../views/user/EWallet/EWalletPage";
import { proRoutes } from "./proRoutes";
import { bookmarkService } from "../services/bookmark/bookmarkService.tsx";
import BookmarksPage from "../views/user/BookmarksPage.tsx";

const { isLoggedIn } = authService();
const { getLoggedUser } = userService();
const {
  getCompanyHeadcounts,
  getCompanyActivities,
  getHierarchyLevels,
  getSkills,
  getExpertises,
  getLanguages,
  getAssociations,
} = dataService();
const {
  getCurrentUserBookingProfile,
  getBookingProfileBySlugAndUsername,
  getBookingProfileByRequestUuid,
} = bookingProfileService();
const { getBookingRequestByUuid } = bookingRequestService();
const {
  setPaymentIntentStatus,
  getWalletTransactionsForCurrentUser,
  getVouchersForCurrentUser,
} = paymentService();
const { getAllBookmarks } = bookmarkService();

export const privateRoutes: RouteObjectWithCondition = {
  path: "app", // private
  sxRedirectTo({ request }) {
    const url = new URL(request.url);
    const splittedPath = url.pathname.split("/");
    return isLoggedIn()
      ? null
      : (splittedPath[splittedPath.length - 1] ?? "") === "profiles-list"
        ? `/profiles-list?${url.searchParams.toString()}`
        : `/login?redirectTo=${encodeURIComponent(url.pathname + url.searchParams.toString())}`;
  },
  element: <AppLayout />,
  loader: async () => {
    return promiseAllObject({
      loggedUser: getLoggedUser(),
      bookingProfile: getCurrentUserBookingProfile("tisio"),
    });
  },
  children: [
    {
      // *** Main user area
      path: "",
      children: [
        {
          index: true,
          loader() {
            return redirect("/app/dashboard");
          },
        },
        {
          path: "dashboard",
          element: <UserDashboardPage />,
          loader: async () => {
            return promiseAllObject({
              loggedUser: getLoggedUser(),
              tisioProfile: getCurrentUserBookingProfile("tisio").catch(() =>
                Promise.resolve(undefined),
              ),
              dataForUserDashboard: getDataForUserDashboardRequest(),
            });
          },
        },
        {
          path: "my-account",
          element: <AccountPage />,
          children: [
            {
              index: true,
              loader() {
                return redirect("/app/my-account/identity");
              },
            },
            {
              path: "identity",
              element: <UserIdentityForm />,
              loader: async () => {
                const loggedUser = await getLoggedUser();
                const countries = await getAllCountriesRequest();
                return {
                  loggedUser,
                  countries,
                };
              },
            },
            { path: "password", element: <UserPasswordForm /> },
          ],
        },
        {
          path: "e-wallet",
          element: <EWalletPage />,
          async loader() {
            return promiseAllObject({
              loggedUser: getLoggedUser(),
              walletTransactions: getWalletTransactionsForCurrentUser(),
              vouchers: getVouchersForCurrentUser(),
            });
          },
        },
        {
          path: "profiles/:communitySlug",
          element: <BookingProfileManagementPage />,
          async loader({ params }) {
            try {
              return await promiseAllObject({
                profile: getCurrentUserBookingProfile(params.communitySlug!),
                user: getLoggedUser(),
              });
            } catch (err) {
              // TODO: Display an error to the user
              return redirect("/app/dashboard");
            }
          },
          children: [
            {
              index: true,
              loader() {
                return redirect("position");
              },
            },
            {
              path: "position",
              loader() {
                return promiseAllObject({
                  companyActivities: getCompanyActivities(),
                  companyHeadcounts: getCompanyHeadcounts(),
                  hierarchyLevels: getHierarchyLevels(),
                });
              },
              element: <BookingProfilePositionForm />,
            },
            {
              path: "description",
              loader() {
                return promiseAllObject({
                  skills: getSkills(),
                  expertises: getExpertises(),
                  languages: getLanguages(),
                });
              },
              element: <BookingProfileDescriptionForm />,
            },
            {
              path: "preferences",
              loader() {
                return promiseAllObject({
                  associations: getAssociations(),
                });
              },
              element: <BookingProfilePreferencesForm />,
            },
          ],
        },
        {
          path: "my-calendar",
          loader: async () => {
            const loggedUser = await getLoggedUser();
            return {
              loggedUser,
            };
          },
          element: <UserCalendarPage />,
        },
        {
          path: "profiles-list",
          element: <ProfilesListPage />,
          loader: async ({ request }) => {
            // REVIEW
            // get query params from URL, transform them into a object and pass it to the getBookingProfilesRequest function
            const query = {
              limit: "40",
              offset: "0",
            } as Record<string, string>;
            const url = new URL(request.url);
            Array.from(url.searchParams.entries()).forEach(([key, value]) => {
              if (key === "page") {
                if (Number(value) < 1) return;
                const nbElementsPerPage = url.searchParams.get("limit")
                  ? Number(url.searchParams.get("limit"))
                  : Number(query.limit);
                query.offset = String((Number(value) - 1) * nbElementsPerPage);
              } else if (key.toLowerCase().includes("price")) {
                query[key] = String(Number(value) * 100);
              } else if (key === "ref") {
                query.origin = value;
              } else query[key] = value;
            });

            if (!query.sort) {
              query.sort = "rating:desc";
            }

            return promiseAllObject({
              bookingProfilesWithCount: getBookingProfilesRequest(query),
              configData: getAllConfigDataRequest(),
              loggedUser: getLoggedUser(),
              bookmarks: getAllBookmarks(),
            }).then(
              ({
                bookingProfilesWithCount,
                configData,
                loggedUser,
                bookmarks,
              }) => {
                return {
                  bookingProfiles: bookingProfilesWithCount[0],
                  totalProfilesNb: bookingProfilesWithCount[1],
                  configData: mapConfigDataToAutocompleteOptions(configData),
                  loggedUser,
                  bookmarks,
                };
              },
            );
          },
        },
        {
          path: "bookings/:uuid/closure",
          element: <BookingClosurePage />,
          async loader({ params }) {
            const [currentUser, bookingRequest, otherPartyBookingProfile] =
              await Promise.all([
                getLoggedUser(),
                getBookingRequestByUuid(params.uuid),
                getBookingProfileByRequestUuid(params.uuid),
              ]);

            return promiseAllObject({
              bookingRequest: Promise.resolve(bookingRequest),
              currentUserBookingProfile: getCurrentUserBookingProfile(
                bookingRequest.recipient?.userId === currentUser.id
                  ? bookingRequest.recipient.community.slug
                  : bookingRequest.sender!.community.slug,
              ),
              otherPartyBookingProfile: Promise.resolve(
                otherPartyBookingProfile,
              ),
            });
          },
        },
        {
          path: "c/:communitySlug/:username",
          element: <BookingProfilePage />,
          loader: async ({ params }) => {
            const currentUser = await getLoggedUser();
            try {
              return promiseAllObject({
                bookingProfile: getBookingProfileBySlugAndUsername(
                  params.communitySlug!,
                  params.username!,
                ),
                currentUserBookingProfile: getCurrentUserBookingProfile(
                  params.communitySlug!,
                ),
                communitySlug: Promise.resolve(params.communitySlug),
                username: Promise.resolve(params.username),
                currentUser: Promise.resolve(currentUser),
                currentUserCredits: getUserCreditsRequest(currentUser.id),
              });
            } catch (err) {
              // TODO: Display an error to the user
              return redirect("/app/dashboard");
            }
          },
        },
        {
          path: "bookings/:uuid",
          element: <BookingRequestPage />,
          loader: async ({ params, request }) => {
            const url = new URL(request.url);

            const [bookingRequest, currentUser] = await Promise.all([
              getBookingRequestByUuid(params.uuid),
              getLoggedUser(),
            ]);

            // Used to hide stripe redirect and params from user
            if (url.searchParams.has("redirect_status")) {
              await setPaymentIntentStatus(
                bookingRequest.id!,
                url.searchParams.get("redirect_status")!,
              );
              return redirect(".");
            }

            return promiseAllObject({
              bookingRequest: Promise.resolve(bookingRequest),
              currentUser: Promise.resolve(currentUser),
              currentUserCredits: getUserCreditsRequest(currentUser.id),
            });
          },
        },
        {
          path: "bookings/:uuid/payments",
          element: <BookingRequestPaymentPage />,
          errorElement: <BookingRequestNotFoundPage />,
          loader: async ({ params }) => {
            const bookingRequest = await getBookingRequestByUuid(params.uuid);
            if (bookingRequest.status !== "CREATED") {
              return redirect(`/app/bookings/${bookingRequest.uuid}`);
            }
            return {
              bookingRequest,
            };
          },
        },
        {
          path: "bookings",
          element: <RequestsAndBookingsPage />,
          loader: async () => {
            const loggedUser = await getLoggedUser();
            return promiseAllObject({
              currentUserBookingRequests: getCurrentUserBookingRequestsRequest(
                loggedUser.id,
              ),
              loggedUser: Promise.resolve(loggedUser),
            });
          },
          children: [
            {
              index: true,
              loader() {
                return redirect("appointments");
              },
            },
            {
              path: "appointments",
              element: <AppointmentsSection />,
            },
            {
              path: "requests",
              element: <RequestsSections />,
            },
          ],
        },
        {
          path: "bookmarks",
          element: <BookmarksPage />,
          loader() {
            return promiseAllObject({
              bookmarks: getAllBookmarks(),
            });
          },
        },
      ],
    },
    {
      // *** admin area
      path: "admin",
      children: [
        {
          index: true,
          loader() {
            return redirect("/app/admin/dashboard");
          },
        },
        {
          path: "dashboard",
          element: <AdminPage />,
        },
      ],
    },
    proRoutes,
  ],
};
