import ProfilePic from "../../../../components/profileDisplay/ProfilePic";
import { BookingProfile } from "../../../../services/bookingProfile/bookingProfileModel";
import { User } from "../../../../services/user/userModel";
import BookingProfileStatuses from "../BookingProfileStatuses";
import ProfilePosition from "../../../../components/profileDisplay/ProfilePosition";
import ProfileTags from "../../../../components/profileDisplay/ProfileTags";
import ProfileDescription from "../../../../components/profileDisplay/ProfileDescription";
import ProfilePrice from "../../../../components/profileDisplay/ProfilePrice";
import LinkedinLink from "../../../../components/profileDisplay/LinkedinLink";
import Icon from "../../../../components/Icon";
import { Tooltip } from "react-tooltip";
import SubmitButton from "../../../../components/forms/SubmitButton";
import { authService } from "../../../../services/auth/authService";
import { cx } from "../../../../react-helpers/css";
import { useTranslation } from "react-i18next";
import Dialog from "../../../../components/Dialog";
import SxAvatarInput from "../../UserAccount/SxAvatarInput";
import { userService } from "../../../../services/user/userService";
import { readFile } from "../../../../react-helpers/files";
import { useState } from "react";
import useReload from "../../../../hooks/useReload";
import RatingDisplay from "./RatingDisplay";

const { isLoggedIn, getLoggedUserId } = authService();
const { updateUserById } = userService();

function ProfileDisplay({
  user,
  profile,
  publicDisplay = false,
  bookmarked,
  onBookmark,
  ratingAmount,
  commentsAmount,
  onRatingsClick,
}: {
  user: User;
  profile: BookingProfile;
  publicDisplay?: boolean;
  bookmarked?: boolean;
  onBookmark?(profile: BookingProfile): Promise<void>;
  ratingAmount?: number | null;
  commentsAmount?: number | null;
  onRatingsClick?(): void;
}) {
  const { t } = useTranslation(["bookingProfile"]);

  const reload = useReload();
  const [showEditProfilePicture, setShowEditProfilePicture] = useState(false);

  return (
    <div className="profile-display">
      <div className="profile_row">
        <div className="lrow --block --distribute--h --center--v">
          {publicDisplay ? (
            <ProfilePic src={user.profilePicture} />
          ) : (
            <button
              className="link --soft"
              onClick={(ev) => {
                ev.preventDefault();
                setShowEditProfilePicture(true);
              }}
            >
              <ProfilePic
                src={user.profilePicture}
                className={!user.profilePicture ? "--editable" : ""}
              />
            </button>
          )}

          {isLoggedIn() &&
            getLoggedUserId() !== user.id &&
            bookmarked !== undefined && (
              <SubmitButton
                data-tooltip-id="bookmark-tooltip"
                data-tooltip-content={
                  bookmarked
                    ? t("bookingProfile:bookmark.REMOVE")
                    : t("bookingProfile:bookmark.ADD")
                }
                className={cx([
                  "profile_bookmark-btn",
                  bookmarked && "--active",
                ])}
                onClick={(e) => {
                  e.preventDefault();
                  return onBookmark?.(profile);
                }}
                loadingMsg={null}
              >
                <Icon name="bookmark_plain" />
              </SubmitButton>
            )}

          <Tooltip id="bookmark-tooltip" />
        </div>

        <div className="profile_info-col">
          <div>
            <div className="lrow --center--v --distribute --gap--v--xxs">
              <div className="profile_name">
                {publicDisplay
                  ? user.displayName
                  : `${user.firstname} ${user.lastname}`}
              </div>
              <div className="ui-row --stretch">
                <BookingProfileStatuses profile={profile} />
                {publicDisplay && (
                  <>
                    {profile.association?.validated && (
                      <span className="status --dark">
                        {profile.association.labelFr}
                        <span
                          data-tooltip-id="asso-tooltip"
                          data-tooltip-html={
                            profile.probono
                              ? `Ce profil reverse l’ensemble<br/>de ses gains à l’association<br/>${profile.association.labelFr}`
                              : `10% des gains de ce profil sont<br/>reversés à l’association<br/>${profile.association.labelFr}`
                          }
                        >
                          <Icon name="info" />
                        </span>
                      </span>
                    )}
                    <Tooltip id="asso-tooltip" />
                  </>
                )}
              </div>
            </div>

            <RatingDisplay
              profile={profile}
              commentsAmount={commentsAmount}
              ratingAmount={ratingAmount}
              onRatingsClick={onRatingsClick}
            />
            <LinkedinLink profile={profile} />
          </div>

          <ProfilePosition profile={profile} />

          <ProfileTags profile={profile} />

          <ProfileDescription
            profile={profile}
            publicDescription={publicDisplay}
          />
        </div>
      </div>

      <ProfilePrice profile={profile} publicPrice={true} />

      {showEditProfilePicture && (
        <Dialog onClose={() => setShowEditProfilePicture(false)}>
          <>
            <div className="popup_head">
              <div className="popup_title">
                Mise à jour de votre photo de profil
              </div>
            </div>
            <div className="popup_body">
              <SxAvatarInput
                className="--l"
                value={user.profilePicture ?? undefined}
                onChange={async (profilePicture) => {
                  const updatedProfilePicture = profilePicture
                    ? await readFile(profilePicture as File)
                    : null;
                  return updateUserById({
                    ...user,
                    profilePicture: updatedProfilePicture,
                  }).then(() => {
                    reload();
                    setShowEditProfilePicture(false);
                  });
                }}
              />
            </div>
          </>
        </Dialog>
      )}
    </div>
  );
}

export default ProfileDisplay;
