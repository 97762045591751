import { boolean, object, string } from "yup";
import SxForm from "../../../../forms/SxForm";
import SxField from "../../../../forms/fields/SxField";
import SxCheckbox from "../../../../forms/fields/SxCheckbox";
import { Link } from "react-router-dom";
import ValidationErrors from "../../../../forms/ValidationErrors";
import SubmitButton from "../../../../components/forms/SubmitButton";
import { Plan } from "../../../../services/businessAccount/businessAccountModel";
import DisplayPrice from "../../../../components/DisplayPrice";
import { ProRegisterData } from "../../../../services/auth/authModel";
import PlanCard from "../../../../components/plans/PlanCard";
import {
  checkSiret,
  transformEmptyToNull,
  transformEmptyToUndefined,
} from "../../../../react-helpers/yup";
import { isValidCouponRequest } from "../../../../services/coupon/couponApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useField, useFormikContext } from "formik";
import Icon from "../../../../components/Icon";
import { debounceAsync } from "../../../../react-helpers/function";
import { Coupon } from "./PlanSubscriptionPage";

const CouponField = ({
  name,
  error,
  setError,
  coupon,
  setCoupon,
}: {
  name: string;
  error: string | null;
  setError: (error: string | null) => void;
  coupon: Coupon | null;
  setCoupon: (
    coupon: Coupon | null | ((prev: Coupon | null) => Coupon | null),
  ) => void;
}) => {
  const [field, meta] = useField(name);
  const formik = useFormikContext();

  // when error state change we revalidate the form
  useEffect(() => {
    void formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.validateForm, error]);

  const isValidCoupon = useCallback(
    async (coupon: string) => {
      return isValidCouponRequest(coupon).then(
        ({ data }) => {
          // We store the valid coupon in a state to access its properties
          setCoupon((prev) => (prev?.code !== data.code ? data : prev));
          return null;
        },
        (err) => {
          setCoupon(null);
          switch (err.response?.status) {
            case 404:
              return "Ce code n'existe pas";
            case 417:
              return "Ce code n'est pas encore actif";
            case 412:
              return "Ce code n'est plus actif";
            case 409:
              return "Ce coupon est invalide";
          }
          return "Une erreur est survenue";
        },
      );
    },
    [setCoupon],
  );

  const debouncedIsValidCoupon = useMemo(
    () => debounceAsync(isValidCoupon, 500),
    [isValidCoupon],
  );

  useEffect(() => {
    // If field is empty, we reset the coupon and the errors
    if (!field.value || field.value.length === 0) {
      setError(null);
      setCoupon(null);
      return;
    }

    // If the field is not empty, we valid it and set errors/coupon accordingly
    void (async () => {
      const result = await debouncedIsValidCoupon(field.value);
      if (!result) setError(null);
      else setError(result);
    })();
  }, [field.value, debouncedIsValidCoupon, setError, setCoupon]);

  return (
    <div>
      <SxField name={name} />
      <div className="field-tip">
        {(field.value?.length ?? 0) > 0 && !meta.error && coupon ? (
          <div className="ui-row --center--v">
            <Icon name="check-round" className="--s --green" />
            {coupon.freeDays} jours gratuits
          </div>
        ) : (
          // empty field-tip to avoid flickering
          <span>&nbsp;</span>
        )}
      </div>
    </div>
  );
};

const PlanRegisterForm = ({
  onRegister,
  plan,
  yearly,
  coupon,
  setCoupon,
}: {
  onRegister: (data: ProRegisterData) => Promise<void>;
  plan: Plan;
  yearly: boolean;
  coupon: Coupon | null;
  setCoupon: (
    coupon: Coupon | null | ((prev: Coupon | null) => Coupon | null),
  ) => void;
}) => {
  const [couponError, setCouponError] = useState<string | null>(null);

  const schema = useMemo(
    () =>
      object({
        firstname: string().label("Prénom").required().min(2).trim(),
        lastname: string().label("Nom").required().min(2).trim(),
        email: string().label("Adresse email").email().required(),
        tel: string()
          .label("Numéro de téléphone")
          .phoneNumber("Le numéro de téléphone est invalide")
          .transform(transformEmptyToUndefined)
          .required("Le numéro de téléphone est requis"),
        password: string().label("Mot de passe").password().required(),
        cguAccepted: boolean()
          .transform((v) => !!v)
          .oneOf([true]),
        companyName: string().label("Nom de l'entreprise").required().trim(),
        siret: string().label("Siret").required().trim().test(checkSiret),
        stripePlanId: string().label("Formule").required().trim(),
        couponCode: string()
          .label("Code promo")
          .trim()
          .transform(transformEmptyToNull)
          .nullable()
          .test({
            // couponError is externalised to another component to support debouncing
            test: (_value, ctx) =>
              !couponError || ctx.createError({ message: couponError }),
          }),
      }),
    [couponError],
  );

  return (
    <SxForm
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        tel: "",
        password: "",
        cguAccepted: false,
        companyName: "",
        stripePlanId: yearly
          ? plan.annualStripePriceId
          : plan.monthlyStripePriceId,
        siret: "",
        couponCode: null,
      }}
      onSubmit={async (values) => onRegister({ ...values, planId: plan.id })}
      validationSchema={schema}
      validateOnChange
    >
      {({ values, setFieldValue }) => (
        <div className="grid--1-2">
          <div className="card --bg">
            <div className="card_body">
              <PlanCard
                displayCta={false}
                yearly={values.stripePlanId === plan.annualStripePriceId}
                onChange={(p) =>
                  void setFieldValue(
                    "stripePlanId",
                    p === 1
                      ? plan.monthlyStripePriceId
                      : plan.annualStripePriceId,
                    false,
                  )
                }
              />
            </div>
          </div>
          <div className="cblocks">
            <div className="card">
              <div className="card_body cblocks">
                <h2 className="section_title">Compte utilisateur</h2>
                <div className="grid--2">
                  <SxField
                    name="firstname"
                    placeholder="Saisissez votre prénom"
                  />
                  <SxField
                    name="lastname"
                    placeholder="Saisissez votre nom de famille"
                  />
                </div>

                <SxField name="email" placeholder="me@mail.com" />
                <SxField name="tel" placeholder="+33 6 00 00 00 00" />
                <SxField name="password" placeholder="************" />
              </div>
            </div>
            <div className="card">
              <div className="card_body cblocks">
                <h2 className="section_title">Entreprise</h2>
                <div className="grid--2">
                  <SxField
                    name="companyName"
                    placeholder="Saisissez le nom de l'entreprise"
                  />
                  <SxField
                    name="siret"
                    placeholder="Saisissez le siret de l'entreprise"
                  />
                </div>
              </div>
            </div>
            <div className="card --bg">
              <div className="card_body cblocks">
                <div className="cblocks grid--2">
                  <SxField name="stripePlanId" as="select">
                    <option value={plan.annualStripePriceId}>
                      Abonnement annuel (
                      <DisplayPrice
                        amount={plan.annualPrice}
                        taxFree
                        currency="EUR"
                        textOnly
                      />{" "}
                      / an)
                    </option>
                    <option value={plan.monthlyStripePriceId}>
                      Abonnement mensuel (
                      <DisplayPrice
                        amount={plan.monthlyPrice}
                        taxFree
                        currency="EUR"
                        textOnly
                      />{" "}
                      / mois)
                    </option>
                  </SxField>
                  <CouponField
                    name="couponCode"
                    error={couponError}
                    setError={setCouponError}
                    coupon={coupon}
                    setCoupon={setCoupon}
                  />
                </div>

                <SxCheckbox
                  name="cguAccepted"
                  label={
                    <>
                      J'ai lu et j'accepte les{" "}
                      <Link to="/general-conditions-of-use" target="_blank">
                        conditions générales d’utilisation
                      </Link>{" "}
                      de la plateforme.
                    </>
                  }
                />
              </div>
            </div>

            <div className="form_footer">
              <ValidationErrors />
              <div className="cblock">
                <SubmitButton className="btn" type="submit">
                  Souscrire
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </SxForm>
  );
};

export default PlanRegisterForm;
