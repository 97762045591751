import { useNavigate, useSearchParams } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import { authService } from "../../../services/auth/authService";

function RegisterPage() {
  const auth = authService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <div className="auth-card">
      <div className="card_head">
        <h1 className="card_title">Rejoignez Tisio !</h1>
        <p>Créez votre compte en un instant</p>
      </div>
      <div className="card_body">
        <RegisterForm
          onRegister={async (registerData) => {
            return auth
              .register({
                ...registerData,
                origin: searchParams.get("origin") ?? null,
                cguAccepted: registerData.cguAccepted,
              })
              .then(() => {
                return navigate("/login");
              });
          }}
        />
      </div>
    </div>
  );
}

export default RegisterPage;
