import { Link, useLoaderData } from "react-router-dom";
import { Bookmark } from "../../services/bookmark/bookmarkModel";
import BackButton from "../../components/BackButton";
import { useSearch } from "../../react-helpers/array";
import BookingProfileCard from "../../components/profiles/BookingProfileCard";
import { createRef } from "react";
import Icon from "../../components/Icon";
import { useConfirmationWithIntl } from "../../components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { bookmarkService } from "../../services/bookmark/bookmarkService";
import useReload from "../../hooks/useReload";

const { deleteBookmark } = bookmarkService();

const BookmarksPage = () => {
  const { bookmarks } = useLoaderData() as {
    bookmarks: Bookmark[];
  };

  const { t } = useTranslation(["bookingProfile"]);
  const { confirm } = useConfirmationWithIntl(["bookingProfile"]);
  const reload = useReload();

  const [searchedBookmarks, setSearch] = useSearch(bookmarks);
  const scrollAnchor = createRef<HTMLDivElement>();

  return (
    <div className="container">
      <div ref={scrollAnchor} />
      <div className="page_head">
        <div>
          <BackButton />
          <h1 className="page_title">Vos profils enregistrés</h1>
        </div>
      </div>

      {bookmarks.length === 0 && (
        <div className="page-content">
          <div className="callout --light">
            <Icon name="search" />
            <div>
              <strong>AUCUN PROFIL ENREGISTRÉ</strong>
              <br />
              pour le moment.
            </div>
            <div className="cblocks --xs">
              <Link className="btn--2" to={"../profiles-list"}>
                Rechercher un profil
              </Link>
            </div>
          </div>
        </div>
      )}

      {bookmarks.length > 0 && (
        <div className="page-content">
          <input
            className="input"
            onChange={(ev) => setSearch(ev.target.value)}
            placeholder={"Rechercher dans les profils enregistrés"}
          />
          <div className="lblock--xl">
            <div className="cblock">
              {searchedBookmarks.length > 0 ? (
                <div className="booking-profiles-list">
                  {searchedBookmarks.map((bookmark) => (
                    <BookingProfileCard
                      key={bookmark.id}
                      minimal
                      bookingProfile={bookmark.bookingProfile}
                      bookmarked={true}
                      onBookmark={() => {
                        confirm(
                          "bookingProfile:remove-bookmark.CONFIRM",
                          async () => {
                            await deleteBookmark(bookmark.id);
                            reload();
                          },
                          true,
                          {
                            confirmText: t(
                              "bookingProfile:remove-bookmark.SUBMIT",
                            ),
                            cancelText: t(
                              "bookingProfile:remove-bookmark.CANCEL",
                            ),
                          },
                        );
                        return Promise.resolve();
                      }}
                    />
                  ))}
                </div>
              ) : (
                <div className="info">
                  Aucun profil ne correspond à ces critères de recherche
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookmarksPage;
