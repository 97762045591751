import { NavLink } from "react-router-dom";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import { TISIO_FEE_PERCENTAGE } from "../../services/config";
import ProfilePic from "../../components/profileDisplay/ProfilePic";
import { authService } from "../../services/auth/authService";
import Icon from "../Icon";
import DisplayPrice from "../DisplayPrice";
import SubmitButton from "../forms/SubmitButton";
import { cx } from "../../react-helpers/css";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import RatingDisplay from "../../views/user/BookingProfile/bookingProfileManagementPage/RatingDisplay";

const BookingProfileCard = ({
  bookingProfile,
  minimal = false,
  bookmarked,
  onBookmark,
}: {
  bookingProfile: BookingProfile;
  minimal?: boolean;
  bookmarked?: boolean;
  onBookmark?(profile: BookingProfile): Promise<void>;
}) => {
  const { isLoggedIn } = authService();
  const { t } = useTranslation(["bookingProfile"]);

  return (
    <NavLink
      to={`${isLoggedIn() ? "/app" : ""}/c/${bookingProfile.community.slug}/${bookingProfile.user.username}`}
      className="booking-profile-list_item"
    >
      <div className="profile_row">
        <ProfilePic src={bookingProfile.user.profilePicture} />
        {isLoggedIn() && bookmarked !== undefined && (
          <SubmitButton
            data-tooltip-id="bookmark-tooltip"
            data-tooltip-content={
              bookmarked
                ? t("bookingProfile:bookmark.REMOVE")
                : t("bookingProfile:bookmark.ADD")
            }
            className={cx(["profile_bookmark-btn", bookmarked && "--active"])}
            onClick={(e) => {
              e.preventDefault();
              return onBookmark?.(bookingProfile);
            }}
            loadingMsg={null}
          >
            <Icon name="bookmark_plain" />
          </SubmitButton>
        )}
        <Tooltip id="bookmark-tooltip" />

        <div className="profile_info-col">
          <div>
            <div className="lrow --distribute--h --center--v">
              <div className="profile_name">
                {bookingProfile.user.displayName}
              </div>
              {bookingProfile.probono && <div className="chip">100% don</div>}
            </div>
            {bookingProfile.linkedin && (
              <button
                className="link profile_linkedin-link"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(bookingProfile.linkedin!, "_blank");
                }}
              >
                <Icon name="linkedin" className="--linkedin-blue" />
              </button>
            )}
            <RatingDisplay profile={bookingProfile} />
          </div>

          <div>
            <div className="profile_position">{bookingProfile.position}</div>
            <div className="profile_company-name">
              {bookingProfile.companyName}
              {bookingProfile.companyActivity && (
                <small> ({bookingProfile.companyActivity.labelFr})</small>
              )}
            </div>
          </div>
          <div className="profile_tags">
            <div className="ui-row --s">
              {bookingProfile.expertises.map((expertise) => (
                <span key={expertise.id} className="tag">
                  {expertise.labelFr}
                </span>
              ))}
            </div>
          </div>

          {!minimal && (
            <div className="profile_description">
              <div
                className="description_preview"
                dangerouslySetInnerHTML={{
                  __html:
                    bookingProfile.description ??
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh est, porta a ipsum eu, tempus euismod felis. Sed eu lobortis neque.", // TODO remove temp lorem
                }}
              />
            </div>
          )}
        </div>
      </div>
      {!minimal && (
        <div className="profile_price-display">
          <DisplayPrice
            amount={
              bookingProfile.price *
              (1 + parseInt(TISIO_FEE_PERCENTAGE, 10) / 100)
            }
            className="price"
            addon={<span className="timeslot">/ 30 minutes</span>}
          />
        </div>
      )}
    </NavLink>
  );
};

export default BookingProfileCard;
