import { useLoaderData, useSearchParams } from "react-router-dom";
import { Plan } from "../../../../services/businessAccount/businessAccountModel";
import PlanRegisterForm from "./PlanRegisterForm";
import BankingInfoDialog from "./BankingInfoDialog";
import { useState } from "react";
import { ProRegisterData } from "../../../../services/auth/authModel";

export interface Coupon {
  code: string;
  freeDays: number;
}

const PlanSubscriptionPage = () => {
  const { plan } = useLoaderData() as {
    plan: Plan;
  };
  const [searchParams] = useSearchParams();

  const [user, setUser] = useState<ProRegisterData | null>(null);
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  return (
    <div className="container --wrap--l">
      <div className="page_head">
        <div>
          <h1 className="page_title">Souscription</h1>
          <h2 className="section_title">{plan.labelFr}</h2>
        </div>
      </div>
      <div className="page-content">
        <PlanRegisterForm
          plan={plan}
          yearly={searchParams.has("y")}
          onRegister={(values) => {
            setUser(values);
            return Promise.resolve();
          }}
          coupon={coupon}
          setCoupon={setCoupon}
        />
      </div>
      {user && (
        <BankingInfoDialog
          onClose={() => {
            setUser(null);
          }}
          plan={plan}
          user={user}
          freeDaysAmount={coupon?.freeDays ?? null}
        />
      )}
    </div>
  );
};

export default PlanSubscriptionPage;
