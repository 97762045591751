import { Link } from "react-router-dom";
import { formatDate } from "../../../react-helpers/date";
import {
  Booking,
  BookingRequest,
} from "../../../services/bookingRequest/bookingRequestModel";
import { CAL_BASE_URL } from "../../../services/config";

const CampaignConfirmationInfobox = ({
  booking,
  bookingRequest,
}: {
  booking: Booking;
  bookingRequest: BookingRequest;
}) => {
  const visioLink = `${CAL_BASE_URL}/video/${booking.uid}`;
  const rescheduleLink = `${CAL_BASE_URL}/${bookingRequest.sender!.user.username}/${bookingRequest.sender!.community.slug}-30-minutes?rescheduleUid=${booking.uid}`;

  return (
    <div className="info --success --txt--left cblocks">
      <div>
        Votre visio est confirmée le :
        <div className="body--40 --bold">
          {formatDate(booking.startTime, "PPPP à p")}
        </div>
      </div>
      <div>
        lien de la visio:
        <br />
        <Link
          className="link--accent --s"
          to={visioLink}
          target="_blank"
          rel="noreferrer"
        >
          {visioLink}
        </Link>
      </div>
      <div>
        Si vous avez un empêchement, n'oubliez pas de replanifier le rendez-vous
        en suivant ce lien :
        <br />
        <Link
          className="link--accent --s"
          to={rescheduleLink}
          target="_blank"
          rel="noreferrer"
        >
          {rescheduleLink}
        </Link>
      </div>
    </div>
  );
};

export default CampaignConfirmationInfobox;
