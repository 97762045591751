import { useLoaderData, useNavigate } from "react-router-dom";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import { User } from "../../../services/user/userModel";
import { BookingProfile } from "../../../services/bookingProfile/bookingProfileModel";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import BookingProfileBookingDialog from "./BookingProfileBookingDialog";
import { TISIO_FEE_PERCENTAGE } from "../../../services/config";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";
import BookingProfilePublicAuthDialog from "./BookingProfilePublicAuthDialog";
import ProfileDisplay from "../../user/BookingProfile/bookingProfileManagementPage/ProfileDisplay";
import BackButton from "../../../components/BackButton";
import { bookmarkService } from "../../../services/bookmark/bookmarkService";
import useReload from "../../../hooks/useReload";
import RatingsList from "../../user/BookingProfile/bookingProfileManagementPage/RatingsList";

export interface Request {
  context: string;
  eCredit: number | null;
  voucherId: number | null;
  email?: string;
  firstname?: string;
  lastname?: string;
}

const { createBookmark, deleteBookmark } = bookmarkService();

const BookingProfilePage = () => {
  const navigate = useNavigate();
  const { createBookingRequest } = bookingRequestService();
  const {
    bookingProfile,
    currentUserBookingProfile,
    currentUser,
    currentUserCredits,
  } = useLoaderData() as {
    bookingProfile: BookingProfile;
    currentUserBookingProfile?: BookingProfile;
    currentUser?: User;
    currentUserCredits?: {
      eCredit: User["eCredit"];
      vouchers: User["vouchers"];
    };
  };
  const [request, setRequest] = useState<null | Request>(null);
  const { confirm } = useConfirmationWithIntl("bookingRequest");

  const reload = useReload();

  const onSubmitRequest = useCallback(
    (
      values: Request & {
        senderId: BookingProfile["id"];
        recipientId: BookingProfile["id"];
      },
    ) => {
      const resolve = (request: BookingRequest) =>
        navigate(
          `/app/bookings/${request.uuid}${request.status === "CREATED" ? "/payments" : ""}`,
        );
      if (currentUserCredits) {
        const selectedVoucherAmount =
          currentUserCredits?.vouchers?.find(
            (voucher) => voucher.id === values.voucherId,
          )?.amount ?? null;
        const bookingPaidPrice =
          bookingProfile.price * (1 + TISIO_FEE_PERCENTAGE / 100);

        if (selectedVoucherAmount && selectedVoucherAmount > bookingPaidPrice) {
          confirm(
            "payment.CONFIRM",
            () => createBookingRequest(values).then(resolve),
            true,
          );
          return Promise.resolve();
        }
      }
      return createBookingRequest(values).then(resolve);
    },
    [
      bookingProfile.price,
      confirm,
      createBookingRequest,
      currentUserCredits,
      navigate,
    ],
  );

  // Only for public users, after login/register, we submit the request
  useEffect(() => {
    if (request && currentUser && currentUserBookingProfile) {
      void onSubmitRequest({
        ...request,
        senderId: currentUserBookingProfile.id,
        recipientId: bookingProfile.id,
      }).then(() => setRequest(null));
    }
  }, [
    bookingProfile.id,
    currentUser,
    currentUserBookingProfile,
    onSubmitRequest,
    request,
  ]);
  const [showBookingDialog, setShowBookingDialog] = useState(false);

  const ratingsRef = createRef<HTMLDivElement>();

  const filteredRatings = useMemo(() => {
    return (
      bookingProfile.bookingRequests?.filter((bookingRequest) =>
        bookingRequest.senderId === bookingProfile.id
          ? !!bookingRequest.recipientClosureDate
          : !!bookingRequest.senderClosureDate,
      ) ?? []
    );
  }, [bookingProfile]);

  const ratingAmount = useMemo(() => {
    return filteredRatings?.length ?? null;
  }, [filteredRatings]);
  const commentsAmount = useMemo(() => {
    return (
      filteredRatings?.reduce(
        (acc, bookingRequest) =>
          (bookingRequest.senderId === bookingProfile.id
            ? bookingRequest.recipientComment
            : bookingRequest.senderComment) !== null
            ? acc + 1
            : acc,
        0,
      ) ?? null
    );
  }, [bookingProfile.id, filteredRatings]);

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton fallbackUrl={"/app/profiles-list"} />
          <h1 className="page_title">
            Profil de {bookingProfile.user.displayName}
          </h1>
        </div>
      </div>
      <div className="section cblocks">
        <ProfileDisplay
          profile={bookingProfile}
          user={bookingProfile.user}
          publicDisplay={true}
          bookmarked={(bookingProfile?.bookmarks ?? []).length > 0}
          onBookmark={async () => {
            const bookmarks = bookingProfile?.bookmarks ?? [];
            if (bookmarks.length > 0) {
              await deleteBookmark(bookmarks[0].id);
            } else {
              await createBookmark(bookingProfile.id);
            }

            reload();
          }}
          ratingAmount={ratingAmount}
          commentsAmount={commentsAmount}
          onRatingsClick={() => {
            ratingsRef?.current?.scrollIntoView({ behavior: "smooth" });
          }}
        />
        {/* TODO: add save profile */}

        <div className="--txt--right">
          <button
            type="button"
            className="btn"
            onClick={() => setShowBookingDialog(true)}
          >
            Prendre RDV avec ce profil
          </button>
        </div>
      </div>

      <RatingsList
        commentsAmount={commentsAmount}
        ratingsRef={ratingsRef}
        bookingProfile={bookingProfile}
        ratings={filteredRatings}
        ratingsAmount={ratingAmount}
      />

      {request && (
        <BookingProfilePublicAuthDialog
          request={request}
          bookingProfileId={bookingProfile.id}
          onClose={() => setRequest(null)}
        />
      )}
      {showBookingDialog && (
        <BookingProfileBookingDialog
          currentUser={currentUser}
          onSubmit={(values) => {
            if (currentUser) {
              setShowBookingDialog(false);
              return onSubmitRequest({
                ...values,
                senderId: values.senderId!,
              });
            } else {
              setRequest(values);
              return Promise.resolve();
            }
          }}
          onClose={() => setShowBookingDialog(false)}
          recipientBookingProfile={bookingProfile}
          currentUserBookingProfile={currentUserBookingProfile}
          currentUserCredits={currentUserCredits}
        />
      )}
    </div>
  );
};

export default BookingProfilePage;
