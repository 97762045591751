import { promiseAllObject } from "../react-helpers/promise";
import { businessAccountService } from "../services/businessAccount/businessAccountService";
import { campaignService } from "../services/campaign/campaignService";
import { userService } from "../services/user/userService";
import CampaignsErrorPage from "../views/pro/campaigns/CampaignsErrorPage";
import CampaignsListPage from "../views/pro/campaigns/CampaignsListPage";
import CreateCampaignPage from "../views/pro/campaigns/campaign/CreateCampaignPage";
import EditCampaignPage from "../views/pro/campaigns/campaign/EditCampaignPage";
import ContactsImportDialog from "../views/pro/campaigns/campaign/contacts/import/ContactsImportDialog";
import ContactsListTab from "../views/pro/campaigns/campaign/contacts/ContactsListTab";
import CampaignContactsPage from "../views/pro/campaigns/campaign/contacts/CampaignContactsPage";
import { RouteObjectWithCondition } from "./routerConditions.tsx";
import CampaignBookingsPage from "../views/pro/campaigns/campaign/bookings/CampaignBookingsPage";
import BusinessAccountManagementPage from "../views/pro/management/BusinessAccountManagementPage.tsx";
import { getAllCountriesRequest } from "../services/country/countryApi.ts";
import { dataService } from "../services/data/dataService.tsx";
import CampaignBookingsSection from "../views/pro/campaigns/campaign/bookings/CampaignBookingsSection.tsx";
import { Link } from "react-router-dom";
import CsvImportStep from "../views/pro/campaigns/campaign/contacts/import/CsvImportStep.tsx";
import ManualImportStep from "../views/pro/campaigns/campaign/contacts/import/ManualImportStep.tsx";
import Icon from "../components/Icon.tsx";
import { toastsWithIntl } from "../services/toastService.tsx";
import { authService } from "../services/auth/authService.tsx";

const { getAllCampaigns, getCampaign } = campaignService();
const { getBusinessAccount } = businessAccountService();
const { getLoggedUser } = userService();
const { getPublicAssociations } = dataService();
const { getLoggedUserId } = authService();

const { toastError } = toastsWithIntl(["campaign"]);

export const proRoutes: RouteObjectWithCondition = {
  path: "pro",
  async sxRedirectTo() {
    const user = await getLoggedUser().catch(() => Promise.resolve(null));
    const isUserPro = (user?.businessAccounts?.length ?? 0) > 0;

    return isUserPro ? null : "/app";
  },
  children: [
    {
      index: true,
      async sxRedirectTo() {
        const user = await getLoggedUser();
        return `${user.businessAccounts![0].id}/campaigns`;
      },
    },
    {
      path: ":businessAccountId/manage",
      loader({ params }) {
        return promiseAllObject({
          businessAccount: getBusinessAccount(Number(params.businessAccountId)),
          countries: getAllCountriesRequest(),
        });
      },
      element: <BusinessAccountManagementPage />,
    },
    {
      path: ":businessAccountId/campaigns",
      errorElement: <CampaignsErrorPage />,
      async loader({ params }) {
        await getBusinessAccount(Number(params.businessAccountId));

        return null;
      },
      children: [
        {
          index: true,
          loader({ params }) {
            return promiseAllObject({
              campaigns: getAllCampaigns(Number(params.businessAccountId)),
              businessAccount: getBusinessAccount(
                Number(params.businessAccountId),
              ),
            });
          },
          element: <CampaignsListPage />,
        },
        {
          path: "new",
          async sxRedirectTo({ params }) {
            const businessAccount = await getBusinessAccount(
              Number(params.businessAccountId),
            );

            return businessAccount.validSubscriptions.includes("CAMPAIGN")
              ? null
              : `/subscribe?businessAccountId=${params.businessAccountId}`;
          },
          loader({ params }) {
            return promiseAllObject({
              associations: getPublicAssociations(),
              currentUser: getLoggedUser(),
              businessAccount: getBusinessAccount(
                Number(params.businessAccountId),
              ),
            });
          },
          element: <CreateCampaignPage />,
        },
        {
          path: ":campaignId",
          loader({ params }) {
            return promiseAllObject({
              associations: getPublicAssociations(),
              currentUser: getLoggedUser(),
              campaign: getCampaign(Number(params.campaignId)),
            });
          },
          element: <EditCampaignPage />,
        },
        {
          path: ":campaignId/contacts",
          async sxRedirectTo({ params }) {
            const [campaign, businessAccount] = await Promise.all([
              getCampaign(Number(params.campaignId)),
              getBusinessAccount(Number(params.businessAccountId)),
            ]);

            function errorToRoute(error: string) {
              toastError(`campaign:${error}`);
              return import.meta.env.SSR ? `./..?error=${error}` : "./..";
            }

            if (campaign.type === "OPEN") return "./..";
            else if (campaign.status === "DRAFT") {
              return errorToRoute("ERROR_NOT_ACTIVE");
            } else if (!businessAccount.stripeCustomer?.card) {
              return errorToRoute("ERROR_NO_VALID_PAYMENT_METHOD");
            } else if (!campaign.referent?.completedOnboarding) {
              if (campaign.referent?.id === getLoggedUserId()) {
                return errorToRoute("ERROR_NO_REFERENT_CALENDAR_AS_REFERENT");
              } else {
                return errorToRoute("ERROR_NO_REFERENT_CALENDAR_AS_OTHER");
              }
            }

            return null;
          },
          loader({ params }) {
            return promiseAllObject({
              campaign: getCampaign(Number(params.campaignId)),
            });
          },
          element: <CampaignContactsPage />,
          children: [
            {
              path: "",
              element: <ContactsListTab />,
              children: [
                {
                  path: "import",
                  element: <ContactsImportDialog />,
                  children: [
                    {
                      index: true,
                      element: (
                        <div className="container lcol --gap--l --center">
                          <h2 className="page_title --txt-center">
                            Comment souhaitez-vous
                            <br />
                            importer vos contacts ?
                          </h2>
                          <div className="lrow --stretch">
                            <Link className="btn--card" to="csv">
                              <Icon name="svg" />
                              <span>Importer un fichier CSV</span>
                            </Link>
                            <Link className="btn--card" to="manual">
                              <Icon name="keyboard" />
                              <span>Saisir les contacts manuellement</span>
                            </Link>
                          </div>
                        </div>
                      ),
                    },
                    {
                      path: "csv",
                      element: <CsvImportStep />,
                    },
                    {
                      path: "manual",
                      element: <ManualImportStep />,
                    },
                  ],
                },
              ],
            },
            {
              path: "archived",
              element: <ContactsListTab archived />,
            },
          ],
        },
        {
          path: ":campaignId/bookings",
          loader({ params }) {
            return promiseAllObject({
              campaign: getCampaign(Number(params.campaignId)),
              businessAccount: getBusinessAccount(
                Number(params.businessAccountId),
              ),
            });
          },
          element: <CampaignBookingsPage />,
          children: [
            {
              index: true,
              element: <CampaignBookingsSection />,
            },
            /* {
              path: "archived",
              element: <CampaignArchivedBookingsSection />,
            }, */
          ],
        },
      ],
    },
  ],
};
