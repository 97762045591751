import Icon from "../../../../components/Icon";
import { BookingProfile } from "../../../../services/bookingProfile/bookingProfileModel";

const RatingDisplay = ({
  profile,
  ratingAmount,
  commentsAmount,
  onRatingsClick,
}: {
  profile: BookingProfile;
  ratingAmount?: number | null;
  commentsAmount?: number | null;
  onRatingsClick?(): void;
}) => {
  return (
    profile.rating !== null &&
    profile.rating !== undefined && (
      <div className="rating-display">
        <Icon name="star" />{" "}
        <span>
          <strong>{Math.round(profile.rating * 10) / 10}</strong>
          /5
        </span>
        {(ratingAmount ?? commentsAmount) && (
          <button className="link" onClick={onRatingsClick}>
            (
            {[
              ratingAmount && `${ratingAmount} notes`,
              commentsAmount && `${commentsAmount} avis`,
            ]
              .filter((t) => t)
              .join(", ")}
            )
          </button>
        )}
      </div>
    )
  );
};

export default RatingDisplay;
