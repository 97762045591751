import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormikContext } from "formik";
import { isMimeTypeAccepted } from "../../../react-helpers/files";
import { randomUUID } from "../../../react-helpers/crypto";
import { cx } from "../../../react-helpers/css";
import Icon from "../../../components/Icon";
import { ClientOnly } from "../../../react-helpers/react";
import AvatarEditor from "react-avatar-editor";
import SubmitButton from "../../../components/forms/SubmitButton";
import imageCompression from "browser-image-compression";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

type SxFile = File | string;

export interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  onChange?(list: SxFile | null): Promise<unknown> | void;
  dragLabel?: string;
}

function hasUnacceptedFiles(items: DataTransferItemList, accept: string) {
  for (const item of items) {
    if (!isMimeTypeAccepted(accept, item.type)) {
      return true;
    }
  }
}

enum FileStatus {
  NoFile,
  PendingUpload,
  Uploaded,
}

const SxAvatarInputInner = (props: Props) => {
  const formik = useFormikContext();
  const fileZone = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const avatarEditorRef = useRef<AvatarEditor>(null);
  const [dragging, setDragging] = useState(false);
  const dragCountRef = useRef<number>(0);
  const [impossible, setImpossible] = useState<
    false | "not-multiple" | "invalid-mime"
  >(false);

  const {
    onChange,
    accept,
    multiple,
    dragLabel,
    value,
    name,
    className,
    ...otherProps
  } = props;

  const { t } = useTranslation(["accounts"]);

  const [tempFile, setTempFile] = useState<SxFile | null>(null);
  const finalValue = useMemo(() => {
    return value ?? (name && (formik?.values as any)[name]) ?? null;
  }, [formik?.values, name, value]);

  const fileStatus = useMemo(() => {
    if (!finalValue && !tempFile) return FileStatus.NoFile;
    if (tempFile) return FileStatus.PendingUpload;
    return FileStatus.Uploaded;
  }, [finalValue, tempFile]);

  const inputUuid = useMemo(() => randomUUID(), []);

  const handleChange = useCallback(
    async (file: SxFile | null) => {
      if (onChange) {
        await onChange(file);
        setTempFile(null);
      } else if (formik?.setFieldValue && name) {
        void formik.setFieldValue(name, file);
        void formik.setFieldTouched(
          name,
          (formik.initialValues as any)?.[name] !== file,
        );
        setTempFile(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onChange,
      formik?.setFieldValue,
      formik?.initialValues,
      formik?.setFieldTouched,
      name,
    ],
  );

  const compressFile = useCallback(async () => {
    const compressedFile = await new Promise<File>((resolve) => {
      avatarEditorRef.current?.getImage().toBlob((blob) => {
        void imageCompression(blob as File, {
          maxWidthOrHeight: 500,
          maxSizeMB: 0.3,
        }).then((compressedFile) => {
          resolve(compressedFile);
        });
      });
    });

    return handleChange(compressedFile);
  }, [avatarEditorRef, handleChange]);

  // Handle input change
  useEffect(() => {
    function listener() {
      const inputFile = inputFileRef.current!;
      if (!inputFile) return;
      return setTempFile(inputFile.files?.[0] ?? null);
    }

    const inputFile = inputFileRef.current!;
    if (!inputFile) return;
    inputFile.addEventListener("change", listener);
    return () => {
      inputFile.removeEventListener("change", listener);
    };
  }, [inputFileRef]);

  // Handle drag and drop
  useEffect(() => {
    const div = fileZone.current!;
    if (!div) return;
    const handlers = {
      dragenter(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        dragCountRef.current++;
        if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
          setDragging(true);

          if (accept && hasUnacceptedFiles(e.dataTransfer.items, accept))
            setImpossible("invalid-mime");
          else if (!multiple && e.dataTransfer.items.length > 1)
            setImpossible("not-multiple");
        }
      },
      dragleave(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        dragCountRef.current--;
        if (dragCountRef.current === 0) {
          setDragging(false);
          setImpossible(false);
        }
      },
      dragover(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
      },
      drop(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        setImpossible(false);
        if (
          e.dataTransfer?.files &&
          e.dataTransfer.files.length > 0 &&
          (!accept || !hasUnacceptedFiles(e.dataTransfer.items, accept)) &&
          (multiple ?? e.dataTransfer.files.length === 1)
        ) {
          setTempFile(e.dataTransfer.files?.[0]);
        }
        dragCountRef.current = 0;
      },
    };
    Object.entries(handlers).map(([key, value]) =>
      div.addEventListener(
        key as "dragenter" | "dragleave" | "dragover" | "drop",
        value,
      ),
    );

    return () => {
      if (div) {
        Object.entries(handlers).map(([key, value]) =>
          div.removeEventListener(
            key as "dragenter" | "dragleave" | "dragover" | "drop",
            value,
          ),
        );
      }
    };
  }, [fileZone, setTempFile, accept, multiple]);

  return (
    <div
      ref={fileZone}
      className={cx([
        "file-drop --profile-pic",
        className,
        dragging && "active",
      ])}
    >
      <input
        id={inputUuid}
        accept={accept}
        multiple={multiple}
        type={"file"}
        hidden
        {...otherProps}
        ref={inputFileRef}
      />

      {fileStatus === FileStatus.NoFile && (
        <>
          <Icon name={"upload"} className="--blue" />
          {dragLabel ? dragLabel : `Déposez votre fichier ici`}
          <div>ou</div>
          <label className={"upload-btn"} htmlFor={inputUuid}>
            <Icon name={"search_computer"} />
            <div>Recherchez sur votre ordinateur</div>
          </label>
        </>
      )}

      {fileStatus === FileStatus.PendingUpload && tempFile && (
        <>
          <SubmitButton
            className="file-drop_save-btn"
            type="button"
            onClick={compressFile}
            data-tooltip-id="save-avatar-tooltip"
          />
          <button
            className="file-drop_delete-btn"
            type="button"
            onClick={() => setTempFile(null)}
          />
          <Tooltip
            id="save-avatar-tooltip"
            content={t("accounts:update-avatar.SAVE-AVATAR-TIP")}
            data-tooltip-place={"bottom"}
            defaultIsOpen={true}
          />

          <AvatarEditor
            ref={avatarEditorRef}
            image={tempFile}
            style={{ height: "100%", width: "100%" }}
            width={1000}
            height={1000}
            border={100}
          />
        </>
      )}

      {fileStatus === FileStatus.Uploaded && (
        <>
          <canvas
            width="1"
            height="1"
            style={{
              backgroundImage: `url(${typeof finalValue === "string" ? finalValue : URL.createObjectURL(finalValue)})`,
            }}
          />
          <SubmitButton
            className="file-drop_delete-btn"
            type="button"
            onClick={() => handleChange(null)}
          />
        </>
      )}
      {impossible === "invalid-mime" ? (
        <p>Impossible de placer ce type de fichier</p>
      ) : (
        impossible === "not-multiple" && (
          <p>Ce champ ne peut contenir qu'un seul fichier</p>
        )
      )}
    </div>
  );
};

const SxAvatarInput = (props: Props) => {
  // We use the ClientOnly with a function so that the props are evaluated lazily
  return <ClientOnly>{() => <SxAvatarInputInner {...props} />}</ClientOnly>;
};

export default SxAvatarInput;
