import { createRef, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ClientOnly } from "../../react-helpers/react";

function ScrollToTopOnNavInner() {
  const topAnchor = createRef<HTMLDivElement>();
  const location = useLocation();
  const lastNavigationLocation = useRef(location);

  /// Using useLayoutEffect to ensure the scroll happens after the DOM updates but before the paint
  /// Using navigation.state we can ensure to scroll after the navigation is done
  useLayoutEffect(() => {
    const prevLocation = lastNavigationLocation.current;
    if (location?.pathname !== prevLocation?.pathname) {
      topAnchor.current?.scrollIntoView();
      lastNavigationLocation.current = location;
    }
  }, [location, topAnchor]);

  return <div ref={topAnchor}></div>;
}

function ScrollToTopOnNav() {
  return (
    <ClientOnly>
      <ScrollToTopOnNavInner />
    </ClientOnly>
  );
}

export default ScrollToTopOnNav;
