import { Link, useNavigate } from "react-router-dom";
import {
  Booking,
  BookingRequest,
} from "../../../services/bookingRequest/bookingRequestModel";
import CampaignConfirmationInfobox from "./CampaignConfirmationInfobox";

const CampaignConfirmationDialogContent = ({
  booking,
  bookingRequest,
  isLoggedIn,
}: {
  booking: Booking;
  bookingRequest: BookingRequest;
  isLoggedIn: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="popup_head">
        <h1 className="popup_title">Confirmation de l'interview</h1>
      </div>
      <div className="popup_body lblocks">
        <CampaignConfirmationInfobox
          booking={booking}
          bookingRequest={bookingRequest}
        />
        <div className="info">
          Vous allez recevoir un mail de confirmation contenant toutes les
          informations nécessaires.
        </div>

        {!isLoggedIn && (
          <div className="card">
            <div className="card_body">
              <div className="--txt--center cblocks">
                <div className="section_title">
                  Vous souhaitez recevoir d’autres d’opportunités
                  <br />
                  comme celle-ci ?
                </div>

                <div className="ui-row --center">
                  <Link className="btn--2" to="/register">
                    Oui, je crée mon profil Tisio
                  </Link>
                  <button
                    className="btn--cancel"
                    type="button"
                    onClick={() => navigate("/")}
                  >
                    Non merci
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CampaignConfirmationDialogContent;
