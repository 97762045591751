import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Dialog from "../../../../components/Dialog";
import { stripe } from "../../../../services/payment/stripeConfig";
import SubmitButton from "../../../../components/forms/SubmitButton";
import SxForm from "../../../../forms/SxForm";
import { ProRegisterData } from "../../../../services/auth/authModel";
import { authService } from "../../../../services/auth/authService";
import { addDays } from "date-fns";
import { formatDate } from "../../../../react-helpers/date";
import { Plan } from "../../../../services/businessAccount/businessAccountModel";
import DisplayPrice from "../../../../components/DisplayPrice";
import { useNavigate } from "react-router-dom";

const { registerPro } = authService();

const BankingInfoForm = ({
  user,
  onClose,
}: {
  onClose: () => void;
  user: ProRegisterData;
}) => {
  // const { toastSuccess, toastError } = toastsWithIntl(["auth"]);
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (!error && paymentMethod) {
        await registerPro({
          ...user,
          paymentMethodId: paymentMethod.id,
        }).then(
          () => {
            onClose();
            navigate("/login");
          },
          () => {
            onClose();
          },
        );
      }
    }
  };

  return (
    <SxForm initialValues={{}} onSubmit={handleSubmit}>
      <div className="popup_body lblocks">
        <div className="card-element-wrapper">
          <CardElement />
        </div>
      </div>

      <div className="popup_footer">
        <SubmitButton type="submit" className="btn">
          Valider
        </SubmitButton>
      </div>
    </SxForm>
  );
};

const BankingInfoDialog = ({
  onClose,
  user,
  plan,
  freeDaysAmount,
}: {
  onClose: () => void;
  user: ProRegisterData;
  plan: Plan;
  freeDaysAmount: number | null;
}) => {
  return (
    <Dialog onClose={onClose}>
      <>
        <div className="popup_head">
          <div className="popup_title">Paiement sécurisé</div>
        </div>
        <div>
          <div className="popup_body">
            <div className="card --bg">
              <div className="card_body --txt--center cblocks --xs">
                {plan.annualStripePriceId === user.stripePlanId ? (
                  <>
                    <h1 className="page_title">
                      <DisplayPrice
                        amount={plan.annualPrice * 1.2}
                        taxFree={false}
                        currency="EUR"
                      />{" "}
                      <small>
                        (
                        <DisplayPrice
                          amount={plan.annualPrice}
                          taxFree
                          currency="EUR"
                        />
                        )
                      </small>
                    </h1>
                    {user.couponCode && freeDaysAmount ? (
                      <p className="body--30">
                        prélevés à l'issue de votre période d'essai de{" "}
                        {freeDaysAmount} jours, le{" "}
                        {formatDate(addDays(new Date(), freeDaysAmount))}, date
                        à laquelle votre abonnement d'un an prendra effet.
                      </p>
                    ) : (
                      <p className="body--30">
                        Prélèvement annuel à compter du {formatDate(new Date())}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <h1 className="page_title">
                      <DisplayPrice
                        amount={plan.monthlyPrice * 1.2}
                        taxFree={false}
                        currency="EUR"
                      />{" "}
                      <small>
                        (
                        <DisplayPrice
                          amount={plan.monthlyPrice}
                          taxFree
                          currency="EUR"
                        />
                        )
                      </small>
                    </h1>
                    {user.couponCode && freeDaysAmount ? (
                      <p className="body--30">
                        Prélèvement mensuel à compter de la fin de votre période
                        d'essai de {freeDaysAmount} jours, le{" "}
                        {formatDate(addDays(new Date(), freeDaysAmount))}
                      </p>
                    ) : (
                      <p className="body--30">
                        Prélèvement mensuel à compter du{" "}
                        {formatDate(new Date())}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Elements stripe={stripe}>
          <BankingInfoForm user={user} onClose={onClose} />
        </Elements>
      </>
    </Dialog>
  );
};

export default BankingInfoDialog;
